<template>
	<div class="driverInformation">
		<jy-query ref="checkForm" :model="formInline">
			<jy-query-item label="车牌号:" prop="plateNum">
				<el-input v-model="formInline.plateNum" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="自编号:" prop="customNo">
				<el-input v-model="formInline.customNo" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="所属机构:" prop="orgNa">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="formInline.orgNa" readonly></el-input>
				</div>
			</jy-query-item>
			<jy-query-item label="vin:" prop="vin">
				<el-input v-model="formInline.vin" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="车辆状态:" prop="status">
				<el-select v-model="formInline.status" placeholder="请选择">
					<el-option v-for="item in statuList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="燃料类型:" prop="fuelT">
				<el-select v-model="formInline.fuelT" filterable>
					<el-option v-for="(value, key) in fuelTMap" :key="key" :value="key" :label="value"></el-option>
				</el-select>
			</jy-query-item>
			<!-- <jy-query-item label="所属线路:" prop="routeId">
                <el-select v-model="formInline.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item> -->
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicVehicleList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicVehicleReset')"
					>重置</el-button
				>
			</template>
			<template slot="operate">
				<!-- <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicVehicleList')">查询</el-button>
				<el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicVehicleReset')">重置</el-button> -->
				<!-- <el-button type="primary" @click="onexport">导出</el-button>
                <el-button type="primary" @click="batchimport">导入</el-button> -->
				<!-- <el-row> -->
				<el-button type="primary" @click="add" v-if="btnexist('scheduleBasicVehicleInsert')">新增</el-button>
				<!-- <el-button type="primary" @click="batchdelete">批量删除</el-button> -->
				<el-button type="primary" @click="onexport" v-if="btnexist('scheduleBasicVehicleExport')">导出</el-button>
				<el-button type="primary" @click="batchimport" v-if="btnexist('scheduleBasicVehicleImport')">导入</el-button>
				<!-- </el-row> -->
			</template>
		</jy-query>
		<jy-table max-height="540" ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<jy-table-column type="selection" width="40" fixed="left"></jy-table-column>
			<jy-table-column prop="plateNum" label="车牌号" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="customNo" label="自编号" min-width="120" fixed="left"></jy-table-column>
			<!-- <jy-table-column prop="routName" label="所属线路" min-width="100" fixed="left"></jy-table-column> -->
			<jy-table-column prop="orgNa" label="所属机构" min-width="220"></jy-table-column>
			<jy-table-column prop="driverNames" label="已关联司机" min-width="160"></jy-table-column>
			<jy-table-column prop="vin" label="vin" min-width="200"></jy-table-column>
			<jy-table-column prop="vendor" label="车辆厂商" min-width="100"></jy-table-column>
			<jy-table-column prop="model" label="车辆型号" min-width="100"></jy-table-column>
			<jy-table-column prop="deviceIds" label="关联设备" min-width="160"></jy-table-column>
			<jy-table-column label="车辆状态" min-width="100">
				<template slot-scope="scope">{{ scope.row.status | status }}</template>
			</jy-table-column>
			<jy-table-column label="车辆类型" min-width="100">
				<template slot-scope="scope">{{ scope.row.vType | vType }}</template>
			</jy-table-column>
			<jy-table-column prop="batterV" label="车辆电池容量" min-width="120"></jy-table-column>
			<jy-table-column prop="color" label="颜色" min-width="100"></jy-table-column>
			<jy-table-column label="燃料类型" min-width="100">
				<template slot-scope="scope">{{ scope.row.fuelT | fuelT }}</template>
			</jy-table-column>
			<jy-table-column prop="purchaseD" label="购置日期" min-width="100"></jy-table-column>
			<jy-table-column prop="seats" label="座位数" min-width="100"></jy-table-column>
			<jy-table-column prop="maxiNum" label="最大核载数" min-width="100"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<add-update ref="addUpdate" @finished="getlist"></add-update>
		<clear-plan ref="clear" @update="getlist"></clear-plan>

		<jy-dialog title="文本下发" :visible.sync="dialogVisible">
			<div class="dia_box">
				<el-input type="textarea" :rows="2" placeholder="请输入文本内容" v-model="diaObj.message"></el-input>
			</div>
			<template #footer>
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" @click="save">确定</el-button>
			</template>
		</jy-dialog>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
		<jy-import
			ref="jyImport"
			:headers="uploadHeaders"
			:action="action"
			@downloadTemplate="downloadTemplate"
			@getElxData="getElxData"
			@confirm="confirm"
			:successLength="correctCount"
			:errList="listError"
			:status.sync="importStatus"
		></jy-import>
	</div>
</template>
<script>
import addUpdate from "@/components/pages/admin/basicInformation/vehicleInformation/addUpdate";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import { btnMixins } from "@/common/js/button.mixin";
import ClearPlan from "@/components/pages/admin/basicInformation/vehicleInformation/clearPlan";
export default {
	data() {
		return {
			formInline: {
				plateNum: "",
				customNo: "",
				orgId: "",
				orgNa: "",
				vin: "",
				status: "",
				fuelT: ""
			},
			lists: [],
			// 批量删除
			batchdeleteList: [],
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10,
			operateList: [],
			routeOptions: [],

			dialogVisible: false,
			diaObj: {
				vehicleId: "",
				message: ""
			},
			//导入
			uploadHeaders: {
				token: this.$util.getStor("token")
			},
			action: "/ischedule/base/vehicle/importVehicleCach",
			importId: "",
			listError: [],
			correctCount: "",
			errorCount: "",

			importStatus: 1,
			btnMenuId: "scheduleBasicVehicle",
			statuList: [
				{
					value: "1",
					label: "正常"
				},
				{
					value: "2",
					label: "停运"
				},
				{
					value: "3",
					label: "维修"
				},
				{
					value: "4",
					label: "报废"
				}
			],
			fuelTMap: {
				0: "汽油",
				1: "柴油",
				2: "混合动力",
				3: "纯电动"
			}
		};
	},
	mixins: [btnMixins],
	components: {
		addUpdate,
		ClearPlan,
		institutionsTree
	},
	watch: {
		dialogVisible(val) {
			if (!val) {
				this.diaObj = {
					vehicleId: "",
					message: ""
				};
			}
		}
	},
	filters: {
		vType(val) {
			if (val == 0) {
				return "公交车";
			} else if (val == 1) {
				return "货车";
			}
		},
		status(val) {
			let v = "";
			switch (val) {
				case "1":
					v = "正常";
					break;
				case "2":
					v = "停运";
					break;
				case "3":
					v = "维修";
					break;
				case "4":
					v = "报废";
					break;
			}
			return v;
		},
		fuelT(val) {
			let v = "";
			switch (val) {
				case "0":
					v = "汽油";
					break;
				case "1":
					v = "柴油";
					break;
				case "2":
					v = "混合动力";
					break;
				case "3":
					v = "纯电动";
					break;
			}
			return v;
		}
	},
	created() {
		this.getlist();
		this.setOperateList();
	},
	activated() {
		// this.getRouteList()
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		setOperateList() {
			let l = [
				{
					name: "修改",
					fun: this.update,
					isShow: () => {
						return this.btnexist("scheduleBasicVehicleUpdate");
					}
				},
				{
					name: "删除",
					fun: this.deleteRow,
					isShow: () => {
						return this.btnexist("scheduleBasicVehicleDelete");
					}
				},
				{
					name: "文本下发",
					fun: row => {
						this.diaObj.vehicleId = row.vehicleId;
						this.dialogVisible = true;
					},
					isShow: () => {
						return this.btnexist("scheduleBasicVehicleTextSend");
					}
				},
				{
					name: "路单",
					fun: row => {
						// let url = '/waybillPlan/cancelVehicleUnDepShift'
						// let option = {
						//     plateNum:row.plateNum,
						// }
						// this.$http.post(url,option).then(data=>{
						//     this.$message({
						//         message: data.msg,
						//         type: 'success'
						//     })
						//     this.getlist()
						// })
						this.handleClear(row);
					},
					isShow: () => {
						return this.btnexist("scheduleBasicVehicleClear");
					}
				}
				// {
				//     name: '取消路单',
				//     fun: (row) => {
				//         let url = '/waybillActual/cancelVehicleActual'
				//         let option = {
				//             plateNum:row.plateNum,
				//         }
				//         this.$http.post(url,option).then(data=>{
				//             this.$message({
				//                 message: data.msg,
				//                 type: 'success'
				//             })
				//             this.getlist()
				//         })
				//     },
				//     isShow: () => { return this.btnexist('scheduleBasicVehicleClearActual') }
				// },
			];
			this.operateList = l;
		},
		getlist() {
			let url = "/base/vehicle/queryPage";
			let option = {
				...this.formInline,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				let list = detail.list.map(item => {
					item.deviceIds = item.deviceInfos ? item.deviceInfos.map(item => item).join(",") : "";
					return {
						...item,
						...item.sysOrg,
						...item.vehicleInfo,
						...item.vehicleInfoEx
					};
				});
				console.log(list);
				this.lists = list;
				this.total = detail.total;
			});
		},
		// 所属机构
		addInstitutions(data) {
			console.log(data);
			this.formInline.orgId = data.orgId;
			this.formInline.orgNa = data.orgNa;
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formInline.routeId = "";
			this.formInline.orgId = "";
			this.oncheck();
		},
		// 导出
		onexport() {
			console.log("导出");
			let url = "/base/vehicle/exportVehicle";
			let params = {
				orgId: this.formInline.orgId,
				plateNum: this.formInline.plateNum,
				customNo: this.formInline.customNo,
				vin: this.formInline.vin,
				status: this.formInline.status,
				fuelT: this.formInline.fuelT
			};
			console.log(params);
			this.$http.post(url, params, { isExcel: true }).then(res => {
				if (res.status == 200) {
					this.$util.funDownload(res.data, "车辆信息");
				} else {
					this.$message({
						message: "导出失败",
						type: "warning"
					});
				}
			});
		},
		// 批量导入
		// batchimport() {

		// },
		//导入
		batchimport() {
			this.$refs.jyImport.init();
		},
		downloadTemplate() {
			let url = "/base/vehicle/downloadTemplate";
			this.$http.post(url, {}, { type: "format", isExcel: true }).then(res => {
				this.$util.funDownload(res.data, "车辆模板");
			});
		},
		getElxData(data) {
			console.log(data.detail);
			this.importId = data.detail.importId;
			//let url = '/baseinfodriver/importDriverCheck'
			let url = "/base/vehicle/importVehicleCheck";
			let params = {
				importId: data.detail.importId
			};
			this.$http.post(url, params).then(data => {
				let { detail } = data;
				this.listError = detail.listError;
				this.correctCount = detail.correctCount;
				this.errorCount = detail.errorCount;
			});
		},
		confirm() {
			let params = {
				importId: this.importId
			};
			let url = "/base/vehicle/importVehicleData";
			this.$http.post(url, params).then(
				data => {
					if (data.code == 0) {
						this.importStatus = 1;
						this.oncheck();
					}
				},
				() => {
					this.importStatus = -1;
				}
			);
		},
		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},

		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		},
		add() {
			this.$refs.addUpdate.init();
		},
		update(row) {
			let option = {
				...row
			};
			this.$refs.addUpdate.init(option, "update");
		},
		deleteRow(row) {
			var url = "/base/vehicle/del";
			var option = {
				vehicleId: row.vehicleId
			};
			this.$confirm("是否删除该车辆?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$http.post(url, option).then(() => {
					this.getlist();
					this.$message({
						type: "success",
						message: "删除成功!"
					});
				});
			});
		},
		save() {
			if (!this.diaObj.message) {
				this.$message({
					type: "warning",
					message: "请输入文本内容"
				});
				return false;
			}
			let url = "/waybillPlan/textSend";
			let option = {
				...this.diaObj
			};
			this.$http.post(url, option).then(() => {
				this.dialogVisible = false;
				this.getlist();
				this.$message({
					type: "success",
					message: "下发成功!"
				});
			});
		},

		//导出
		exportList() {
			let url = "/baseinfovehicle/export";
			let option = {
				...this.formInline
			};
			this.$http.post(url, option, { type: "format", isExcel: true }).then(res => {
				this.$util.funDownload(res.data, "车辆信息");
			});
		},

		handleClear(row) {
			this.$refs.clear.init(row);
		}
	}
};
</script>
<style lang="scss" scoped="driverInformation"></style>
<style lang="scss"></style>
